import React from 'react';
import { Input } from 'reactstrap';
import { Card, Button,CardBody } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export default class Login extends React.Component{
    static contextType = AppContext;
    constructor(props){
        super(props);
        this.state = {
            usu:'',
            pwd:''
        };
    }
    componentDidMount() {
        const edoGlobal = this.context;
        if(edoGlobal.urlws === 'https://localhost:44355/'){
            this.state.usu = 'cesar';
            this.state.pwd = 'abc123';
        }
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    loguear  = async() => {
        const edoGlobal = this.context;
        const usu = this.state.usu;
        const pwd = this.state.pwd;
        let url = `${edoGlobal.urlws}/home/loguearBank?email=${usu}&pwd=${pwd}`;
        if(edoGlobal.estadosCuenta)
            url = `${edoGlobal.urlws}/home/loguear?email=${usu}&pwd=${pwd}`;
        const req = await fetch(url);
        const res = await req.json();
        if(res.Id === 0){
            Swal.fire('Atencion','Datos incorrectos','error');
            return;
        }
        this.props.iniciar(res);
    }
    render(){
        const edoGlobal = this.context;
        return(
            <>
                <div className="login-box">
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <img src="imagenes/logoCh.png" alt="oporfin" />
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg">Iniciar Sesión</p>
                            <form>
                                <div className="input-group mb-3">
                                    {/* <input type="email" className="form-control" placeholder="Email" /> */}
                                    <Input size="sm" type="email" placeholder="Email" name="usu" value={this.state.usu} onChange={this.onChange} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    {/* <input type="password" class="form-control" placeholder="Password"></input> */}
                                    <Input size="sm" type="password" placeholder="Password" name="pwd" value={this.state.pwd} onChange={this.onChange} />
                                    {/* <input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange} /> */}
                                    <div className="input-group-append">
                                        <span className="input-group-text"><i class="fas fa-lock"></i></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">
                                                Recordarme
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-4">

                                        {/* <Button color="success" size="sm" outline onClick={this.loguear}>Iniciar sesion</Button> */}
                                        <Button onClick={this.loguear} size="sm" color="primary" >Login</Button>
                                    </div>
                                </div>
                            </form>
                            <p className="mb-1">
                                <a href="#">Olvidé mi contraseña</a>
                            </p>

                        </div>
                    </div>
                </div>

            </>
        )
    }
}